<template>
  <div class="subPage">
    <div class="subPageTitle">
      <div class="subPageTitleText">
        <p>提货查询</p>
      </div>
    </div>
    <!-- <generalBill :table-datas="tableDatas" user-type="seller" @upData="getDatas" /> -->
    <div class="tableList">
      <el-table :data="tableDatas">
        <!-- <el-table-column label="序号" type="index" show-overflow-tooltip width="50">
        </el-table-column>-->
        <el-table-column prop="deliveryCode" label="取货编码"></el-table-column>
        <el-table-column prop="warehouseCode" label="入库单编码" width="100"></el-table-column>
        <el-table-column prop="commodityName" label="商品"></el-table-column>
        <el-table-column prop="storageName" label="仓库"></el-table-column>
        <el-table-column prop="specificationModel" label="商品属性">
          <template slot-scope="scope">
            <span>{{
              scope.row.specificationModel | firstAttributeTransformate
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="deliveryWeight" label="重量">
          <template slot-scope="scope">
            <span>{{ scope.row.deliveryWeight }}{{ scope.row.unit }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="consigneeName" label="提货人"></el-table-column>
        <el-table-column prop="consigneePhone" label="提货电话" width="120"></el-table-column>
        <el-table-column prop="shipTime" label="提货时间">
          <template slot-scope="scope">
            <span>{{ scope.row.shipTime | DateFormateDay }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="auditStatus" label="状态">
          <template slot-scope="scope">
            <span>{{ scope.row.deliveryStatus | pickUpGoodsStatus }}</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <div class="operateButtons">
              <el-button v-if="active" size="mini" style="width: 88px!important" @click="showDetails(scope.row)">查看</el-button>
              <el-button
                v-if="scope.row.auditStatus == 'W' && scope.row.deliveryStatus == 'W'"
                style="width: 88px!important"
                type="danger"
                size="mini"
                @click="cancelApply(scope.row)"
              >撤销</el-button>
              <el-button
                v-if="(scope.row.auditStatus == 'W' || scope.row.auditStatus == 'N' ) && scope.row.deliveryStatus == 'W'"
                style="width: 88px!important"
                type="primary"
                size="mini"
                @click="amendApply(scope.row)"
              >修改</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        :current-page="current"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalPage"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
    <amendDialog ref="amendDialog" :source="currentSource" :bs-flag="currentDerict" @reLoad="getDatas()" />
  </div>
</template>
<script>
/* global  protocolNJP http */
import { mapGetters } from 'vuex'
import amendDialog from './amendDialog'
export default {
  data() {
    return {
      active: false,
      current: 1,
      pageSize: 10,
      totalPage: null,
      tableDatas: [],
      currentSource: 0,
      currentDerict: 'S'
    }
  },
  computed: {
    ...mapGetters(['sessionInfoGetter'])
  },
  components: {
    amendDialog
  },
  mounted() {
    this.getDatas()
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val
      this.getDatas()
    },
    handleCurrentChange(val) {
      this.current = val
      this.getDatas()
    },
    getDatas() {
      protocolNJP.param_takeDelivery.param.page = this.current - 1
      protocolNJP.param_takeDelivery.param.size = this.pageSize
      protocolNJP.param_takeDelivery.param.deliveryId = null
      protocolNJP.param_takeDelivery.param.sort = [
        { property: 'deliveryId', direction: 'DESC' }
      ]
      // protocolNJP.param_takeDelivery.param.typeInList = [0, 1, 2]
      http.postFront(protocolNJP.param_takeDelivery).then(response => {
        const { code, message, value } = response.data
        if (code == 0) {
          for (let i = 0; i < value.content.length; i++) {
            if (!value.content[i].warehousId) {
              value.content[i].warehousId = '---'
            }
          }
          this.tableDatas = value.content
          this.totalPage = value.totalElements
        } else {
          this.$EL_MESSAGE(message)
        }
      })
    },
    // 撤销
    cancelApply(row) {
      console.log(row, 'xxxxxx')
      this.$confirm('请确认是否撤销该提货申请？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // applyWarehousId 入库单编号
        protocolNJP.param_deleteDelivery.param.deliveryId = row.deliveryId
        http.postFront(protocolNJP.param_deleteDelivery).then((response) => {
          const { code, message } = response.data
          if (code == 0) {
            this.$EL_MESSAGE(message)
            this.getDatas()
          } else {
            this.$EL_MESSAGE(message)
          }
        })
      }).catch(() => {})
    },
    // 修改
    amendApply(row) {
      this.currentDerict = 'S'
      this.$refs.amendDialog.showDialog(row)
    }
  }
}
</script>
<style scoped lang="scss">
.subPage {
  padding: 0 15px;
}
.subPageTitle {
  overflow: hidden;
}
.subPageTitle .subPageTitleText {
  float: left;
  line-height: 40px;
}
.subPageTitle .subPageTitleText p {
  font-size: 16px;
}
.tableList {
  margin-top: 20px;
}
.tableList {
  margin: 10px 0;
  /deep/ .el-table thead th {
    background-color: rgba(5, 64, 106, 1);
    color: #fff;
  }
  .allSelectBox {
    line-height: 30px;
    margin-left: 20px;
  }
}
.deliveryContent {
  line-height: 30px;
}
.subPage .pagination {
  margin-top: 15px;
}
.operateButtons button {
  display: block;
  margin: 5px auto;
}
</style>
